import React, { Fragment } from 'react';
import { toJS } from 'mobx';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } from 'react-contextmenu';
import Color from 'color';
import moment from 'moment';

import * as rts from '../constants/routes';
import * as fn from './_functions';
import * as ph from './personHelper';
import * as ch from './customerHelper';
import * as adh from './addressHelper';
import * as th from './timelineHelper';
import * as bh from './badgeHelper';

import { CURRENT_USER } from '../constants/storageKeys';

import api from '../api';

export const getStatusDescription = status => {
    if (!status) return;

    switch (status) {
        case 'Booked':
            return 'Booked';

        case 'Canceled':
            return 'Canceled';

        case 'Rescheduled':
            return 'Rescheduled';

        case 'Completed':
            return 'Completed';

        case 'NoShow':
            return 'No show';

        case 'Deleted':
            return 'Deleted';

        default:
            return;
    }
}

export const isUpcoming = appointment => {
    return appointment && appointment.status === 'Booked' && moment(appointment.start).isSameOrAfter(moment());
}

export const isEndState = appointment => {
    return appointment && ['Completed', 'Canceled', 'NoShow', 'Rescheduled', 'Deleted'].some(s => s === appointment.status);
}

export const isDeactivatedState = appointment => {
    return appointment && ['Canceled', 'NoShow', 'Rescheduled', 'Deleted'].some(s => s === appointment.status);
}

export const isAllAreStatus = (appointments, statuses) => {
    if (appointments && statuses) {
        if (!Array.isArray(appointments)) {
            appointments = [appointments];
        }
        return (Array.isArray(statuses) ? appointments.filter(a => statuses.some(s => s === a.status)) : appointments.filter(a => a.status === statuses)).length === appointments.length;
    }

    return false;
}

export const isSomeAreStatus = (appointments, statuses) => {
    if (appointments && statuses) {
        if (!Array.isArray(appointments)) {
            appointments = [appointments];
        }
        return (Array.isArray(statuses) ? appointments.filter(a => statuses.some(s => s === a.status)) : appointments.filter(a => a.status === statuses)).length > 0;
    }

    return false;
}

export const getIndividualAppointments = appointments => {
    let individualAppointments;

    if (appointments && appointments.length > 0) {
        individualAppointments = appointments.filter(a => !a.groupId);
    }

    return individualAppointments && individualAppointments.length > 0 ? individualAppointments : [];
}

export const getGroupAppointments = appointments => {
    const nonObservedAppointments = toJS(appointments);
    let groupAppointments;

    if (nonObservedAppointments && nonObservedAppointments.length > 0) {
        groupAppointments = nonObservedAppointments
            .filter(a => !!a.groupId)
            .reduce((groups, item) => {
                const index = groups.findIndex(g => g.groupId === item.groupId && g.userId === item.userId);
                const appointmentItem = toJS(item);

                if (index > -1) {
                    groups[index].appointments.push(appointmentItem);
                    groups[index].appointments = groups[index].appointments.sort((a, b) => {
                        if (a.customer.id === a.primaryContactId) {
                            return -1;
                        }
                        else if (a.customer.dateOfBirth && b.customer.dateOfBirth) {
                            return moment(a.customer.dateOfBirth) - moment(b.customer.dateOfBirth);
                        }
                        else {
                            return a.customer.firstName - b.customer.firstName;
                        }
                    });
                }
                else {
                    groups.push({
                        groupId: item.groupId,
                        userId: item.userId,
                        primaryAppointment: appointmentItem,
                        resource: item.resource,
                        appointments: [appointmentItem],
                    })
                }

                return groups;
            }, [])
    }

    return groupAppointments && groupAppointments.length > 0 ? groupAppointments : [];
}

export const groupAppointmentNotes = appointments => {
    const nonObservedAppointments = toJS(appointments);
    const notes = nonObservedAppointments && nonObservedAppointments.length > 0 ? nonObservedAppointments.reduce((array, appointment) => {
        if (appointment && appointment.notes && appointment.notes.length > 0) {
            for (var ni = 0; ni < appointment.notes.length; ni++) {
                const { bodyHtml } = appointment.notes[ni];
                const groupNoteIndex = array.findIndex(n => n.bodyHtml === bodyHtml && nonObservedAppointments.some(a => a.id === n.referenceId));

                if (groupNoteIndex < 0) {
                    appointment.notes[ni].ids = [appointment.notes[ni].id];
                    appointment.notes[ni].customerIds = [appointment.notes[ni].customerId];
                    appointment.notes[ni].customers = [appointment.notes[ni].customer];
                    array.push(appointment.notes[ni]);
                }
                else {
                    array[groupNoteIndex].ids.push(appointment.notes[ni].id);
                    array[groupNoteIndex].customerIds.push(appointment.notes[ni].customerId);
                    array[groupNoteIndex].customers.push(appointment.notes[ni].customer);
                    array[groupNoteIndex].ids = Array.from(new Set([...array[groupNoteIndex].ids]));
                    array[groupNoteIndex].customerIds = Array.from(new Set([...array[groupNoteIndex].customerIds]));
                    array[groupNoteIndex].customers = Array.from(new Set([...array[groupNoteIndex].customers]));
                }
            }
        }
        return array;
    }, []) : []

    return notes.sort((a, b) => { return moment(a.createdDateUtc).isBefore(moment(b.createdDateUtc)) ? -1 : 1 });
}

export const renderAppointmentDateTime = (appointment) => {
    if (!appointment.start) return null;

    const start = moment(appointment.start);
    const end = appointment.end ? moment(appointment.end) : null;
    const hasEndTime = !!end;
    const weekday = start.format('dddd');
    const day = start.format('D');
    const ordinal = start.format('Do').replace(day, '');
    const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
    const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
    const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

    return <ul className='list-inline no-style m-0'>
        <li className='list-inline-item m-0'>
            <div className='text'>
                <small className='weekday'>{weekday}</small>
                <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
            </div>
        </li>
        <li className='list-inline-item my-0 mx-1'><small>@</small></li>
        <li className='list-inline-item m-0'>
            <div className='text'>
                <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
            </div>
        </li>
        <li className='list-inline-item my-0 mx-1'><small>to</small></li>
        <li className='list-inline-item m-0'>
            <div className='text'>
                <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
            </div>
        </li>
    </ul>
}

export const renderAppointmentCustomer = (appointment, quickDrawer) => {
    return <div
        className='profile-wrapper'
    >
        <div className='profile'>
            <span
                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(appointment.customer)} fw-500`}
                title={appointment.customer.fullName}
            >
                {appointment.customer.initials}
            </span>
        </div>
        <div className='description'>
            <Link to={`${rts.Customers.Home}/${appointment.customer.id}`} className='name text-gray-700' onClick={quickDrawer ? quickDrawer.deactivateAll : null}>{ph.getFullName(appointment.customer, true)}
                {
                    appointment.customer.dateOfBirth || appointment.customer.sex || appointment.customer.gender || appointment.customer.pronoun ?
                        <small className='text-nowrap ml-2'>({`${ph.getAge(appointment.customer.dateOfBirth, moment(appointment.start))} ${ph.getSexGenderPronounDisplay(appointment.customer)}`.trim()})</small> : null
                }
            </Link>
            {
                appointment.customer.address && appointment.customer.address.country ?
                    <div className='info'>{adh.getAddressHtml(appointment.customer.address)}</div> : null
            }
            {
                appointment.customer.emailAddress ?
                    <div className='info'>
                        <a
                            href={`mailto:${appointment.customer.emailAddress}`}
                        >{appointment.customer.emailAddress}
                        </a>
                    </div> : null
            }
            {
                appointment.customer.phoneNumber ?
                    <div className='info'>
                        <a
                            href={`tel:${appointment.customer.phoneNumber}`}
                        >{appointment.customer.phoneNumber}
                        </a>
                    </div> : null
            }
        </div>
    </div>
}

export const renderAppointmentTimelineBadgeStyles = (appointments) => {
    const colors = appointments && appointments.length > 0 ?
        [...new Set(appointments.map(a => { return a.timeline.map(t => t.colorHexValue) }).reduce((a, b) => [...a, ...b]))] : [];

    return colors.map((c, ci) => {
        return <style
            key={`timeline_${ci}`}
            dangerouslySetInnerHTML={{
                __html: `.__apt_stl_badge_${c.replace('#', '')} {
                    background-color: ${c};
                }`
            }}></style>
    });
}
export const renderDayEvent = (event, element, currentAppointmentId, slotDuration, onClick, onDoubleClick) => {
    if (event) {
        const { extendedProps } = event;
        if (extendedProps && extendedProps.isGroup) {
            return renderGroupAppointmentDayEvent(event, element, currentAppointmentId, slotDuration, onclick, onDoubleClick);
        }
        else {
            return renderIndividualAppointmentDayEvent(event, element, currentAppointmentId, slotDuration, onclick, onDoubleClick);
        }
    }
}

export const renderIndividualAppointmentDayEvent = (event, element, currentAppointmentId, slotDuration, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    const appointmentStart = moment(event.start);
    const isFuture = appointmentStart.clone().startOf('day').diff(moment().startOf('day')) > 0;
    const timeline = extendedProps.appointment.timeline && extendedProps.appointment.timeline.length > 0 ? extendedProps.appointment.timeline.sort((a, b) => a.displayOrder - b.displayOrder) : [];
    let age = extendedProps.appointment.customer ? ph.getAge(extendedProps.appointment.customer.dateOfBirth, (isFuture ? moment() : appointmentStart)) : '';
    let gender = extendedProps.appointment.customer ? ph.getSexGenderPronounDisplay(extendedProps.appointment.customer) : '';
    let size = '';
    let eventColor = extendedProps.appointment.services[0] ? Color(extendedProps.appointment.services[0].colorHexValue) : null;

    // if (timeline && timeline.length > 1) {
    //     if (timeline[0].displayOrder > timeline[1].displayOrder) {
    //         console.log('found');
    //     }
    // }

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('appointment-event');

    if (extendedProps.appointment.duration < slotDuration) {
        size = 'event-sm';
    } else if (extendedProps.appointment.duration >= slotDuration && extendedProps.appointment.duration < (slotDuration * 2)) {
        size = 'event-md';
    } else if (extendedProps.appointment.duration >= (slotDuration * 2) && extendedProps.appointment.duration < (slotDuration * 4)) {
        size = 'event-lg';
    } else {
        size = 'event-xl';
    }

    ReactDOM.render(
        <>
            <Tooltip
                position='left'
                arrow={false}
                trigger='click'
                delay={500}
                html={renderIndividualAppointmentTooltipContent(event, age, gender, extendedProps)}
            >
                {
                    timeline && timeline.length > 0 ?
                        <ul className={'timeline' + (currentAppointmentId && currentAppointmentId !== event.id && currentAppointmentId !== event.groupId ? ' not-selected' : '')}>
                            {
                                timeline.map((s, si) => {
                                    return <li
                                        key={`${extendedProps.appointment.id}_timeline_${si}`}
                                        className={s.performedDateUtc ? '' : ' d-none'}
                                        data-badge={s.name.substring(0, 1).toUpperCase()}
                                    >
                                        <span
                                            className={`badge ` + (s.colorHexValue ? `__apt_stl_badge_${s.colorHexValue.replace('#', '')}` : '')}
                                            title={th.format(extendedProps.appointment, s, s.tooltipFormat)}
                                        >
                                            {s.name.substring(0, 1).toUpperCase()}
                                        </span>
                                    </li>
                                })
                            }
                        </ul> : null
                }
                <ContextMenuTrigger id={`context_menu_${extendedProps.appointment.id}`}>
                    <div
                        className={`event-content ${size}` + (extendedProps.appointment.status === 'Completed' ? ' event-completed' : '') + (extendedProps.appointment.status === 'NoShow' ? ' event-noshow' : '') + (currentAppointmentId && currentAppointmentId !== event.id && currentAppointmentId !== event.groupId ? ' not-selected' : '')}
                        style={{
                            borderColor: eventColor ? eventColor : '',
                            borderLeftColor: eventColor ? eventColor : '',
                            backgroundColor: eventColor ? eventColor.fade(0.95) : '',
                        }}
                        onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
                        onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, event) : null}
                    >
                        <h2 className='title'>
                            <span className={'name fw-500 d-inline m-0 mr-1 fs-sm' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-700')}>
                                <span>
                                    {
                                        extendedProps.appointment.notes && extendedProps.appointment.notes.some(n => n.referenceId === extendedProps.appointment.id) ?
                                            <i className='fal fa-comment-alt-lines text-info-900 mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointment.notes && extendedProps.appointment.notes.some(n => n.isPinned) ?
                                            <i className='fas fa-thumbtack text-info rotate-45 flashing mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointment.customer.hasTodayBalance || extendedProps.appointment.customer.hasOutstandingBalance ?
                                            <i className='fas fa-dollar-sign text-danger flashing mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointment.status === 'Completed' ?
                                            <i className='fas fa-check text-success-600 mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointment.status === 'NoShow' ?
                                            <i className='fas fa-exclamation text-danger mr-1'></i> : null
                                    }
                                    {event.title}
                                </span>
                                {
                                    (age || gender) ?
                                        <small className={'age-gender fw-500 m-0 ml-1 fs-85 d-inline'}>
                                            ({(`${age} ${gender}`).trim()})
                                        </small> : null
                                }
                            </span>
                            {bh.renderAppointmentNew(extendedProps.appointment, 'fs-xs mr-1')}
                            {bh.renderAppointmentFirstExam(extendedProps.appointment, 'fs-xs mr-1')}
                            {bh.renderAppointmentPrebook(extendedProps.appointment, 'mr-1')}
                            {
                                extendedProps.appointment.services.map((s, si) => {
                                    return <Fragment
                                        key={`service-${si}`}
                                    >
                                        {bh.renderServiceCode(s, 'fs-xs mr-1', (!s.isEligible || !isEndState(extendedProps.appointment)))}
                                    </Fragment>
                                })
                            }
                        </h2>
                        <div className={'p-time fw-500' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-700')}>
                            {fn.formatTimeFromToHtml(moment(extendedProps.appointment.start), moment(extendedProps.appointment.end))}
                            {
                                extendedProps.appointment.isRepeating ?
                                    <span className='ml-1 text-gray-700'>(# {extendedProps.appointment.repeatIndex} of {extendedProps.appointment.repeatTotal})</span> : null
                            }
                        </div>
                        <div className={'p-notes fw-500' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-700')}>
                            {
                                extendedProps.appointment.notes && extendedProps.appointment.notes.length > 0 && extendedProps.appointment.notes.some(n => n.referenceId === extendedProps.appointment.id) ?
                                    <span
                                        className='html'
                                        dangerouslySetInnerHTML={{
                                            __html: (
                                                extendedProps.appointment.notes.filter(n => n.referenceId === extendedProps.appointment.id)[0].bodyHtml
                                            )
                                        }}
                                    ></span> : null
                            }
                        </div>
                    </div>
                </ContextMenuTrigger>
            </Tooltip>
        </>,
        eventContainer
    );

    return eventContainer;
}

export const renderGroupAppointmentDayEvent = (event, element, currentAppointmentId, slotDuration, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    // const timeline = extendedProps.appointment.timeline && extendedProps.appointment.timeline.length > 0 ? extendedProps.appointment.timeline.sort((a, b) => a.displayOrder - b.displayOrder) : [];

    const timeline = (extendedProps.appointments && extendedProps.appointments.length > 0 ? extendedProps.appointments.reduce((array, item) => {
        if (item && item.timeline && item.timeline.length > 0) {
            for (var ti = 0; ti < item.timeline.length; ti++) {
                const timelineItem = item.timeline[ti];

                if (array.some(a => a.id === timelineItem.id)) {
                    const index = array.findIndex(a => a.id === timelineItem.id);
                    const existingItem = array[index];
                    const isExistingItemAfter = !!timelineItem.performedDateUtc && moment.utc(existingItem.performedDateUtc).isAfter(moment.utc(timelineItem.performedDateUtc));

                    if (!existingItem.performedDateUtc || isExistingItemAfter) {
                        array[index] = timelineItem;
                    }
                }
                else {
                    array.push(toJS(timelineItem));
                }
            }
        }
        return array;
    }, []) : []).sort((a, b) => a.displayOrder - b.displayOrder);
    const services = extendedProps.appointments && extendedProps.appointments.length > 0 ? extendedProps.appointments.reduce((array, item) => {
        if (item && item.services && item.services.length > 0) {
            for (var si = 0; si < item.services.length; si++) {
                const serviceItem = item.services[si];
                if (!array.some(a => a.id === serviceItem.id)) {
                    array.push(toJS(serviceItem));
                }
            }
        }
        return array;
    }, []) : [];
    const notes = groupAppointmentNotes(extendedProps.appointments);
    const isCompleted = isAllAreStatus(extendedProps.appointments, 'Completed');
    const isNoShow = isAllAreStatus(extendedProps.appointments, 'NoShow');
    const isSomeBothCompletedNoShow = isAllAreStatus(extendedProps.appointments, ['Completed', 'NoShow']) && isSomeAreStatus(extendedProps.appointments, 'Completed') && isSomeAreStatus(extendedProps.appointments, 'NoShow');
    let borderColor = services && services.length > 0 && [...new Set(services.map(s => { return s.colorHexValue }))].length === 1 ? Color(services[0].colorHexValue) : '#888888';
    let eventColor = services && services.length > 0 && [...new Set(services.map(s => { return s.colorHexValue }))].length === 1 ? Color(services[0].colorHexValue).fade(0.95) : '#ffffff';
    let newAppointment = extendedProps.appointments && extendedProps.appointments.length > 0 && extendedProps.appointments.some(a => a.isNew) ? extendedProps.appointments.filter(a => a.isNew)[0] : null;
    let firstExamAppointment = extendedProps.appointments && extendedProps.appointments.length > 0 && extendedProps.appointments.some(a => a.isFirstExam && a.isExamRequired) ? extendedProps.appointments.filter(a => a.isFirstExam && a.isExamRequired)[0] : null;
    let prebookAppointment = extendedProps.appointments && extendedProps.appointments.length > 0 && extendedProps.appointments.some(a => a.isPrebook) ? extendedProps.appointments.filter(a => a.isPrebook)[0] : null;
    let size = '';

    // if (timeline && timeline.length > 1) {
    //     if (timeline[0].displayOrder > timeline[1].displayOrder) {
    //         console.log('found');
    //     }
    // }

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('appointment-event');

    if (extendedProps.appointment.duration < slotDuration) {
        size = 'event-sm';
    } else if (extendedProps.appointment.duration >= slotDuration && extendedProps.appointment.duration < (slotDuration * 2)) {
        size = 'event-md';
    } else if (extendedProps.appointment.duration >= (slotDuration * 2) && extendedProps.appointment.duration < (slotDuration * 4)) {
        size = 'event-lg';
    } else {
        size = 'event-xl';
    }

    ReactDOM.render(
        <>
            <Tooltip
                position='left'
                arrow={false}
                trigger='click'
                delay={500}
                html={renderGroupAppointmentTooltipContent(event, '', '', extendedProps)}
            >
                {
                    timeline && timeline.length > 0 ?
                        <ul className={'timeline' + (currentAppointmentId && currentAppointmentId !== event.id && currentAppointmentId !== event.groupId ? ' not-selected' : '')}>
                            {
                                timeline.map((s, si) => {
                                    return <li
                                        key={`${extendedProps.appointment.id}_timeline_${si}`}
                                        className={s.performedDateUtc ? '' : ' d-none'}
                                        data-badge={s.name.substring(0, 1).toUpperCase()}
                                    >
                                        <span
                                            className={`badge ` + (s.colorHexValue ? `__apt_stl_badge_${s.colorHexValue.replace('#', '')}` : '')}
                                            title={th.format(extendedProps.appointment, s, s.tooltipFormat)}
                                        >
                                            {s.name.substring(0, 1).toUpperCase()}
                                        </span>
                                    </li>
                                })
                            }
                        </ul> : null
                }
                <ContextMenuTrigger id={`context_menu_group_${extendedProps.appointment.groupId}`}>
                    <div
                        className={`event-content ${size}` + (isCompleted ? ' event-completed' : '') + (isNoShow ? ' event-noshow' : '') + (isSomeBothCompletedNoShow ? ' event-warning' : '') + (currentAppointmentId && currentAppointmentId !== event.id && currentAppointmentId !== event.groupId ? ' not-selected' : '')}
                        style={{
                            borderColor: borderColor,
                            backgroundColor: eventColor,
                        }}
                        onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
                        onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, event) : null}
                    >
                        <h2 className='title'>
                            <span className={'name fw-500 d-inline m-0 mr-1 fs-sm' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-700')}>
                                <span>
                                    <i className='fas fa-user-friends text-primary-300 fs-90 mr-1'></i>
                                    {
                                        extendedProps.appointments && extendedProps.appointments.some(a => a.notes.some(n => n.referenceId === a.id)) ?
                                            <i className='fal fa-comment-alt-lines text-info-900 mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointments && extendedProps.appointments.some(a => a.notes && a.notes.some(n => n.isPinned)) ?
                                            <i className='fas fa-thumbtack text-info rotate-45 flashing mr-1'></i> : null
                                    }
                                    {
                                        extendedProps.appointments && extendedProps.appointments.some(a => a.customer.hasTodayBalance || a.customer.hasOutstandingBalance) ?
                                            <i className='fas fa-dollar-sign text-danger flashing mr-1'></i> : null
                                    }
                                    {
                                        isCompleted ?
                                            <i className='fas fa-check text-success-600 mr-1'></i> : null
                                    }
                                    {
                                        isNoShow ?
                                            <i className='fas fa-exclamation text-danger mr-1'></i> : null
                                    }
                                    {
                                        isSomeBothCompletedNoShow ?
                                            <i className='fas fa-check text-warning-600 mr-1'></i> : null
                                    }
                                    {event.title}
                                </span>
                                {
                                    extendedProps.appointments.length > 2 ?
                                        <small className={'fw-500 m-0 ml-1 fs-85 d-inline'}>
                                            (+{(extendedProps.appointments.length - 2)})
                                        </small> : null
                                }
                            </span>
                            {newAppointment ? bh.renderAppointmentNew(newAppointment, 'fs-xs mr-1') : null}
                            {firstExamAppointment ? bh.renderAppointmentFirstExam(firstExamAppointment, 'fs-xs mr-1') : null}
                            {prebookAppointment ? bh.renderAppointmentPrebook(prebookAppointment, 'mr-1') : null}
                            {
                                services.map((s, si) => {
                                    let service = s;

                                    if (s.isEligibilityCheckRequired) {
                                        const ineligibleAppointmentIndex = extendedProps.appointments.findIndex(a => a.services.some(sx => sx.id === service.id && !sx.isEligible));

                                        if (ineligibleAppointmentIndex > -1) {
                                            const ineligibleService = extendedProps.appointments[ineligibleAppointmentIndex].services.filter(sx => sx.id === s.id && !sx.isEligible)[0];

                                            if (ineligibleService) {
                                                service = ineligibleService;
                                            }
                                        }
                                    }
                                    return <Fragment
                                        key={`service-${si}`}
                                    >
                                        {bh.renderServiceCode(service, 'fs-xs mr-1', (!service.isEligible || !isEndState(extendedProps.appointment)))}
                                    </Fragment>
                                })
                            }
                        </h2>
                        <div className={'p-notes fw-500' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-700')}>
                            {
                                notes && notes.length > 0 && notes.some(n => extendedProps.appointments.some(a => a.id === n.referenceId)) ?
                                    <span
                                        className='html'
                                        dangerouslySetInnerHTML={{
                                            __html: (
                                                notes.filter(n => extendedProps.appointments.some(a => a.id === n.referenceId))[0].bodyHtml
                                            )
                                        }}
                                    ></span> : null
                            }
                        </div>
                    </div>
                </ContextMenuTrigger>
            </Tooltip>
        </>,
        eventContainer
    );

    return eventContainer;
}

export const renderWeekEvent = (event, element, currentAppointmentId, slotDuration, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    const appointmentStart = moment(event.start);
    const isFuture = appointmentStart.clone().startOf('day').diff(moment().startOf('day')) > 0;
    let size = '';
    let eventColor = extendedProps.appointment.services[0] ? Color(extendedProps.appointment.services[0].colorHexValue) : '';
    let age = extendedProps.appointment.customer ? ph.getAge(extendedProps.appointment.customer.dateOfBirth, (isFuture ? moment() : appointmentStart)) : '';
    let gender = extendedProps.appointment.customer ? ph.getSexGenderPronounDisplay(extendedProps.appointment.customer) : '';

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('appointment-event');

    if (extendedProps.appointment.duration < slotDuration) {
        size = 'event-sm';
    } else if (extendedProps.appointment.duration >= slotDuration && extendedProps.appointment.duration < (slotDuration * 2)) {
        size = 'event-md';
    } else if (extendedProps.appointment.duration >= (slotDuration * 2) && extendedProps.appointment.duration < (slotDuration * 4)) {
        size = 'event-lg';
    } else {
        size = 'event-xl';
    }

    ReactDOM.render(
        <Tooltip
            position='left'
            arrow={false}
            trigger='click'
            delay={500}
            html={renderIndividualAppointmentTooltipContent(event, age, gender, extendedProps)}
        >
            <ContextMenuTrigger id={`context_menu_${extendedProps.appointment.id}`}>
                <div
                    className={`event-content ${size}` + (extendedProps.appointment.status === 'Completed' ? ' event-completed' : '') + (extendedProps.appointment.status === 'NoShow' ? ' event-noshow' : '') + (currentAppointmentId && currentAppointmentId !== event.id && currentAppointmentId !== event.groupId ? ' not-selected' : '')}
                    style={{
                        borderColor: eventColor ? eventColor : '',
                        borderLeftColor: eventColor ? eventColor : '',
                        backgroundColor: eventColor ? eventColor.fade(0.9) : '',
                    }}
                    onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
                    onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, event) : null}
                >
                    <h2 className='title'>
                        <span className={'name fw-500 d-inline m-0 mr-1 fs-sm' + (extendedProps.appointment.status === 'Completed' || extendedProps.appointment.status === 'NoShow' ? ' text-gray-600' : ' text-gray-800')}>
                            <span>
                                {
                                    extendedProps.appointment.customer.hasTodayBalance || extendedProps.appointment.customer.hasOutstandingBalance ?
                                        <i className='fas fa-dollar-sign text-danger flashing mr-1'></i> : null
                                }
                                {
                                    extendedProps.appointment.status === 'Completed' ?
                                        <i className='fas fa-check text-success-600 mr-1'></i> : null
                                }
                                {
                                    extendedProps.appointment.status === 'NoShow' ?
                                        <i className='fas fa-exclamation text-danger mr-1'></i> : null
                                }
                                {event.title}
                            </span>
                            <small className={'age-gender fw-500 m-0 ml-1 fs-85' + (age && (gender) ? ' d-inline' : ' d-none')}>
                                ({(`${age} ${gender}`).trim()})
                            </small>
                        </span>
                        {bh.renderAppointmentNew(extendedProps.appointment, 'fs-xs mr-1')}
                        {bh.renderAppointmentFirstExam(extendedProps.appointment, 'fs-xs mr-1')}
                        {bh.renderAppointmentPrebook(extendedProps.appointment, 'fs-xs mr-1')}
                        {
                            extendedProps.appointment.services.map((s, si) => {
                                return <Fragment
                                    key={`service-${si}`}
                                >
                                    {bh.renderServiceCode(s, 'fs-xs mr-1', (!s.isEligible || !isEndState(extendedProps.appointment)))}
                                </Fragment>
                            })
                        }
                    </h2>
                    <div className='p-time text-gray-700'>
                        {fn.formatTimeFromToHtml(moment(extendedProps.appointment.start), moment(extendedProps.appointment.end))}
                        {
                            extendedProps.appointment.isRepeating ?
                                <span className='ml-1 text-gray-600'>(# {extendedProps.appointment.repeatIndex} of {extendedProps.appointment.repeatTotal})</span> : null
                        }
                    </div>
                </div>
            </ContextMenuTrigger>
        </Tooltip>,
        eventContainer
    );

    return eventContainer;
}

export const renderAppointmentContextMenus = (individualAppointments, groupAppointments, callbackOptions) => {
    const individualAppointmentContextMenus = renderIndividualAppointmentContextMenus(individualAppointments, callbackOptions);
    const groupAppointmentContextMenus = renderGroupAppointmentContextMenus(groupAppointments, callbackOptions);

    return [...individualAppointmentContextMenus, ...groupAppointmentContextMenus];
}

export const renderIndividualAppointmentContextMenus = (appointmentEvents, callbackOptions) => {
    const defaults = {
        viewExamCallback: null,
        startExamCallback: null,
        viewAppointmentCallback: null,
        rescheduleAppointmentCallback: null,
        updateAppointmentCallback: null,
        deleteAppointmentCallback: null,
        bookNewAppointmentCallback: null,
        seeNotesCallback: null,
        appointmentTimelineChangeCallback: null,
        appointmentStatusChangeCallback: null,
        purchaseModalCallback: null,
        startAppointmentPurchaseCallback: null,
        startPurchaseCallback: null,
    };
    callbackOptions = { ...defaults, ...callbackOptions }

    const {
        viewExamCallback,
        startExamCallback,
        viewAppointmentCallback,
        rescheduleAppointmentCallback,
        updateAppointmentCallback,
        deleteAppointmentCallback,
        bookNewAppointmentCallback,
        seeNotesCallback,
        appointmentTimelineChangeCallback,
        appointmentStatusChangeCallback,
        purchaseModalCallback,
        startAppointmentPurchaseCallback,
        startPurchaseCallback,
    } = callbackOptions;

    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

    return appointmentEvents ?
        appointmentEvents.map((a, ai) => {
            const isEditable = a.appointment.status !== 'Completed' && a.appointment.status !== 'NoShow' && moment(a.appointment.start).startOf('day').diff(moment().startOf('day')) >= 0;
            const timeline = a.appointment.timeline.filter(s => s.menuFormat);

            return <ContextMenu
                id={`context_menu_${a.appointment.id}`}
                key={`context_menu_${ai}`}
                hideOnLeave={true}
            >
                <MenuItem>
                    <div className='d-flex align-items-center'>
                        <div className='flex-1'>
                            <Link className='d-block text-gray-700 text-truncate text-truncate-sm' to={`${rts.Customers.Home}/${a.appointment.customer.id}`}>See {ph.getPreferredFirstName(a.appointment.customer)}'s profile</Link>
                        </div>
                        <a href={`${rts.Customers.Home}/${a.appointment.customer.id}`} target='_blank' rel='noopener noreferrer'>
                            <i className='fal fa-external-link'></i>
                        </a>
                    </div>
                </MenuItem>
                {
                    viewAppointmentCallback || rescheduleAppointmentCallback || updateAppointmentCallback ?
                        <>
                            {
                                (viewExamCallback && fn.isFunction(viewExamCallback) && a.appointment.examId) ||
                                    (startExamCallback && fn.isFunction(startExamCallback) && !a.appointment.examId) ?
                                    <>
                                        {
                                            viewExamCallback && fn.isFunction(viewExamCallback) && a.appointment.examId ?
                                                <>
                                                    <MenuItem divider />
                                                    <MenuItem
                                                        onClick={e => { viewExamCallback(e, a.appointment) }}
                                                    >
                                                        <span data-view-or-start-exam>View exam</span>
                                                    </MenuItem>
                                                </> : null
                                        }
                                        {
                                            startExamCallback && fn.isFunction(startExamCallback) && !a.appointment.examId && a.appointment.userId === currentUser.id ?
                                                <>
                                                    <MenuItem divider />
                                                    <MenuItem
                                                        onClick={e => { startExamCallback(e, a.appointment) }}
                                                    >
                                                        <span data-view-or-start-exam>Start exam</span>
                                                    </MenuItem>
                                                </> : null
                                        }
                                        <MenuItem divider />
                                    </> : null
                            }
                            {
                                viewAppointmentCallback && fn.isFunction(viewAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { viewAppointmentCallback(e, a.appointment) }}
                                    >
                                        View appointment
                                    </MenuItem> : null
                            }
                            {
                                rescheduleAppointmentCallback && fn.isFunction(rescheduleAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { rescheduleAppointmentCallback(e, a.appointment) }}
                                        disabled={!isEditable}
                                    >
                                        Reschedule appointment
                                    </MenuItem> : null
                            }
                            {
                                updateAppointmentCallback && fn.isFunction(updateAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { updateAppointmentCallback(e, a.appointment) }}
                                        disabled={!isEditable}
                                    >
                                        Change appointment
                                    </MenuItem> : null
                            }
                        </> : null
                }
                {
                    deleteAppointmentCallback || bookNewAppointmentCallback || seeNotesCallback ?
                        <>
                            <SubMenu title='More'>
                                {
                                    deleteAppointmentCallback && fn.isFunction(deleteAppointmentCallback) ?
                                        <MenuItem
                                            disabled={!isEditable}
                                            onClick={e => { deleteAppointmentCallback(e, a.appointment) }}
                                        >
                                            <span data-menu-delete-appointment>Delete appointment</span>
                                        </MenuItem> : null
                                }
                                {
                                    bookNewAppointmentCallback && fn.isFunction(bookNewAppointmentCallback) ?
                                        <MenuItem
                                            onClick={e => { bookNewAppointmentCallback(e, a.appointment) }}
                                        >
                                            Book new appointment
                                        </MenuItem> : null
                                }
                                {
                                    seeNotesCallback && fn.isFunction(seeNotesCallback) ?
                                        <MenuItem
                                            onClick={e => { seeNotesCallback(e, a.appointment) }}
                                        >
                                            See notes
                                        </MenuItem> : null
                                }
                            </SubMenu>
                        </> : null
                }
                {
                    timeline || appointmentTimelineChangeCallback || appointmentStatusChangeCallback ?
                        <>
                            <MenuItem divider />
                            <SubMenu title='Actions'>
                                {
                                    appointmentTimelineChangeCallback && fn.isFunction(appointmentTimelineChangeCallback) ?
                                        <>
                                            {
                                                timeline.map((t, ti) => {
                                                    return <MenuItem
                                                        key={`menu_${a.appointment.id}_${ti}`}
                                                        disabled={!!t.performedDateUtc || !isEditable || !th.isEnabled(a.appointment, t.restriction, t.id)}
                                                        onClick={() => { appointmentTimelineChangeCallback(t, a.appointment) }}
                                                    >
                                                        {
                                                            th.format(a.appointment, t, t.menuFormat)
                                                        }
                                                    </MenuItem>
                                                })
                                            }
                                        </> : null
                                }
                                {
                                    appointmentStatusChangeCallback && fn.isFunction(appointmentStatusChangeCallback) ?
                                        <>
                                            <MenuItem
                                                disabled={a.appointment.status === 'Completed' || a.appointment.status === 'NoShow' || !th.isEnabled(a.appointment, 'AppointmentDayOnly')}
                                                onClick={() => { appointmentStatusChangeCallback(a.appointment, 'Completed') }}
                                            >
                                                Complete appointment
                                            </MenuItem>
                                            <MenuItem
                                                disabled={a.appointment.status === 'Completed' || a.appointment.status === 'NoShow' || !th.isEnabled(a.appointment, 'AppointmentDayOnly')}
                                                onClick={() => { appointmentStatusChangeCallback(a.appointment, 'NoShow') }}
                                            >
                                                Customer no show
                                            </MenuItem>
                                        </> : null
                                }
                            </SubMenu>
                        </> : null
                }
                {
                    purchaseModalCallback || startAppointmentPurchaseCallback || startPurchaseCallback ?
                        <>
                            <MenuItem divider />
                            <SubMenu title='Invoices'>
                                {
                                    purchaseModalCallback && fn.isFunction(purchaseModalCallback) ?
                                        <>
                                            {
                                                a.appointment.purchases && a.appointment.purchases.length > 0 ?
                                                    <>
                                                        <MenuItem className='title'>
                                                            <strong>Recent Invoice(s)</strong>
                                                        </MenuItem>
                                                        <MenuItem divider />
                                                        {
                                                            a.appointment.purchases.map((o, oi) => {
                                                                return <MenuItem
                                                                    key={`purchase-invoice-${oi}`}
                                                                    onClick={() => { purchaseModalCallback(o) }}
                                                                >
                                                                    <div className='d-flex flex-row'>
                                                                        <div className='flex-1'>
                                                                            <span className={(o.status === 'Refunded' || o.status === 'Voided') ? 'text-gray-lighter text-strike' : ''}>Invoice # {o.number}</span>
                                                                        </div>
                                                                        <div className='mt-nh pb-o text-right'>
                                                                            {
                                                                                o.completedDateUtc && o.remainingBalance > 0.0 ?
                                                                                    <i className='fas fa-dollar-sign text-danger flashing ml-2'></i> :
                                                                                    bh.renderPurchaseBalanceStatus(o, 'badge-dot')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </MenuItem>
                                                            })
                                                        }
                                                        <MenuItem divider />
                                                    </> : null
                                            }
                                        </> : null
                                }
                                {
                                    startAppointmentPurchaseCallback && fn.isFunction(startAppointmentPurchaseCallback) ?
                                        <>
                                            {
                                                a.appointment.services.some(s => s.fee > 0 && !s.isSubsidized) && !a.appointment.purchases.some(o => o.referenceId === a.appointment.id) ?
                                                    <>
                                                        <MenuItem
                                                            onClick={() => { startAppointmentPurchaseCallback(a.appointment) }}
                                                        >
                                                            <span className='text-primary'>Create appointment invoice</span>
                                                        </MenuItem>
                                                        <MenuItem divider />
                                                    </> : null
                                            }
                                        </> : null
                                }
                                {
                                    startPurchaseCallback && fn.isFunction(startPurchaseCallback) ?
                                        <MenuItem
                                            onClick={() => { startPurchaseCallback(a.appointment) }}
                                        >
                                            Start new invoice
                                        </MenuItem> : null
                                }
                            </SubMenu>
                        </> : null
                }
            </ContextMenu>
        }) : null
}

export const renderGroupAppointmentContextMenus = (groupAppointmentEvents, callbackOptions) => {
    const defaults = {
        viewExamCallback: null,
        startExamCallback: null,
        viewAppointmentCallback: null,
        rescheduleAppointmentCallback: null,
        updateAppointmentCallback: null,
        deleteAppointmentCallback: null,
        bookNewAppointmentCallback: null,
        seeNotesCallback: null,
        appointmentTimelineChangeCallback: null,
        appointmentStatusChangeCallback: null,
        purchaseModalCallback: null,
        startAppointmentPurchaseCallback: null,
        startPurchaseCallback: null,
    };
    callbackOptions = { ...defaults, ...callbackOptions }

    const {
        viewExamCallback,
        startExamCallback,
        viewAppointmentCallback,
        rescheduleAppointmentCallback,
        updateAppointmentCallback,
        deleteAppointmentCallback,
        bookNewAppointmentCallback,
        seeNotesCallback,
        purchaseModalCallback,
        startAppointmentPurchaseCallback,
        startPurchaseCallback,
    } = callbackOptions;

    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

    return groupAppointmentEvents ?
        groupAppointmentEvents.map((a, ai) => {
            const isEditable = !isAllAreStatus(a.appointments, ['Completed', 'NoShow']) && moment(a.appointment.start).startOf('day').diff(moment().startOf('day')) >= 0;

            return <ContextMenu
                id={`context_menu_group_${a.groupId}`}
                key={`context_menu_group_${ai}`}
                hideOnLeave={true}
            >
                <SubMenu title='See profiles'>
                    {
                        a.appointments.map((ga, gai) => {
                            return <MenuItem key={`context_menu_group_${ai}_profiles_${gai}`}>
                                <div className='d-flex align-items-center'>
                                    <div className='flex-1'>
                                        <Link className='d-block text-gray-700 text-truncate text-truncate-sm' to={`${rts.Customers.Home}/${ga.customer.id}`}>See {ph.getPreferredFirstName(ga.customer)}'s profile</Link>
                                    </div>
                                    <a href={`${rts.Customers.Home}/${ga.customer.id}`} target='_blank' rel='noopener noreferrer'>
                                        <i className='fal fa-external-link'></i>
                                    </a>
                                </div>
                            </MenuItem>
                        })
                    }
                </SubMenu>
                {
                    viewAppointmentCallback || rescheduleAppointmentCallback || updateAppointmentCallback ?
                        <>
                            {
                                (viewExamCallback && fn.isFunction(viewExamCallback) && a.appointment.examId) ||
                                    (startExamCallback && fn.isFunction(startExamCallback) && !a.appointment.examId) ?
                                    <>
                                        {
                                            startExamCallback && fn.isFunction(startExamCallback) && a.appointments.some(ga => !ga.examId) && a.appointment.userId === currentUser.id ?
                                                <>
                                                    <MenuItem divider />
                                                    <SubMenu title='Start exam'>
                                                        {
                                                            a.appointments.filter(ga => !ga.examId).map((ga, gai) => {
                                                                return <MenuItem key={`context_menu_group_${ai}_start_exam_${gai}`}
                                                                    onClick={e => { startExamCallback(e, ga) }}
                                                                >
                                                                    <span data-view-or-start-exam>{ph.getPreferredFirstName(ga.customer)}'s exam</span>
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </SubMenu>
                                                </> : null
                                        }
                                        {
                                            viewExamCallback && fn.isFunction(viewExamCallback) && a.appointments.some(ga => ga.examId) ?
                                                <>
                                                    <MenuItem divider />
                                                    <SubMenu title='View exam(s)'>
                                                        {
                                                            a.appointments.filter(ga => ga.examId).map((ga, gai) => {
                                                                return <MenuItem key={`context_menu_group_${ai}_view_exam_${gai}`}
                                                                    onClick={e => { viewExamCallback(e, ga) }}
                                                                >
                                                                    <span data-view-or-start-exam>{ph.getPreferredFirstName(ga.customer)}'s exam</span>
                                                                </MenuItem>
                                                            })
                                                        }
                                                    </SubMenu>
                                                </> : null
                                        }
                                        <MenuItem divider />
                                    </> : null
                            }
                            {
                                viewAppointmentCallback && fn.isFunction(viewAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { viewAppointmentCallback(e, a) }}
                                    >
                                        View appointment
                                    </MenuItem> : null
                            }
                            {
                                rescheduleAppointmentCallback && fn.isFunction(rescheduleAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { rescheduleAppointmentCallback(e, a) }}
                                        disabled={!isEditable}
                                    >
                                        Reschedule appointment
                                    </MenuItem> : null
                            }
                            {
                                updateAppointmentCallback && fn.isFunction(updateAppointmentCallback) ?
                                    <MenuItem
                                        onClick={e => { updateAppointmentCallback(e, a) }}
                                        disabled={!isEditable}
                                    >
                                        Change appointment
                                    </MenuItem> : null
                            }
                        </> : null
                }
                {
                    deleteAppointmentCallback || bookNewAppointmentCallback || seeNotesCallback ?
                        <>
                            <SubMenu title='More'>
                                {
                                    deleteAppointmentCallback && fn.isFunction(deleteAppointmentCallback) ?
                                        <MenuItem
                                            disabled={!isEditable}
                                            onClick={e => { deleteAppointmentCallback(e, a) }}
                                        >
                                            <span data-menu-delete-appointment>Delete appointment</span>
                                        </MenuItem> : null
                                }
                                {/* {
                                    bookNewAppointmentCallback && fn.isFunction(bookNewAppointmentCallback) ?
                                        <MenuItem
                                            onClick={e => { bookNewAppointmentCallback(e, a) }}
                                        >
                                            Book new appointment
                                        </MenuItem> : null
                                } */}
                                {
                                    seeNotesCallback && fn.isFunction(seeNotesCallback) ?
                                        <MenuItem
                                            onClick={e => { seeNotesCallback(e, a) }}
                                        >
                                            See notes
                                        </MenuItem> : null
                                }
                            </SubMenu>
                        </> : null
                }
                {/* {
                    timeline || appointmentTimelineChangeCallback || appointmentStatusChangeCallback ?
                        <>
                            <MenuItem divider />
                            <SubMenu title='Actions'>
                                {
                                    appointmentTimelineChangeCallback && fn.isFunction(appointmentTimelineChangeCallback) ?
                                        <>
                                            {
                                                timeline.map((t, ti) => {
                                                    return <MenuItem
                                                        key={`menu_${a.appointment.id}_${ti}`}
                                                        disabled={!!t.performedDateUtc || !isEditable || !th.isEnabled(a, t.restriction, t.id)}
                                                        onClick={() => { appointmentTimelineChangeCallback(t, a) }}
                                                    >
                                                        {
                                                            th.format(a, t, t.menuFormat)
                                                        }
                                                    </MenuItem>
                                                })
                                            }
                                        </> : null
                                }
                                {
                                    appointmentStatusChangeCallback && fn.isFunction(appointmentStatusChangeCallback) ?
                                        <>
                                            <MenuItem
                                                disabled={a.appointment.status === 'Completed' || a.appointment.status === 'NoShow' || !th.isEnabled(a.appointment, 'AppointmentDayOnly')}
                                                onClick={() => { appointmentStatusChangeCallback(a, 'Completed') }}
                                            >
                                                Complete appointment
                                            </MenuItem>
                                            <MenuItem
                                                disabled={a.appointment.status === 'Completed' || a.appointment.status === 'NoShow' || !th.isEnabled(a.appointment, 'AppointmentDayOnly')}
                                                onClick={() => { appointmentStatusChangeCallback(a, 'NoShow') }}
                                            >
                                                Customer no show
                                            </MenuItem>
                                        </> : null
                                }
                            </SubMenu>
                        </> : null
                } */}
                {
                    purchaseModalCallback || startAppointmentPurchaseCallback || startPurchaseCallback ?
                        <>
                            <MenuItem divider />
                            <SubMenu title='Invoices'>
                                {
                                    a.appointments.map((ga, gai) => {
                                        return <SubMenu
                                            title={`${ph.getPreferredFirstName(ga.customer)}'s invoices`}
                                            key={`context_submenu_group_${ai}_invoice_${gai}`}
                                        >
                                            {
                                                purchaseModalCallback && fn.isFunction(purchaseModalCallback) ?
                                                    <>
                                                        {
                                                            ga.purchases && ga.purchases.length > 0 ?
                                                                <>
                                                                    <MenuItem className='title'>
                                                                        <strong>{ph.getPreferredFirstName(ga.customer)}'s recent Invoice(s)</strong>
                                                                    </MenuItem>
                                                                    <MenuItem divider />
                                                                    {
                                                                        ga.purchases.map((o, oi) => {
                                                                            return <MenuItem
                                                                                key={`purchase-invoice-${gai}_${oi}`}
                                                                                onClick={() => { purchaseModalCallback(o) }}
                                                                            >
                                                                                <div className='d-flex flex-row'>
                                                                                    <div className='flex-1'>
                                                                                        <span className={(o.status === 'Refunded' || o.status === 'Voided') ? 'text-gray-lighter text-strike' : ''}>Invoice # {o.number}</span>
                                                                                    </div>
                                                                                    <div className='mt-nh pb-o text-right'>
                                                                                        {
                                                                                            o.completedDateUtc && o.remainingBalance > 0.0 ?
                                                                                                <i className='fas fa-dollar-sign text-danger flashing ml-2'></i> :
                                                                                                bh.renderPurchaseBalanceStatus(o, 'badge-dot')
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </MenuItem>
                                                                        })
                                                                    }
                                                                    <MenuItem divider />
                                                                </> : null
                                                        }
                                                    </> : null
                                            }
                                            {
                                                startAppointmentPurchaseCallback && fn.isFunction(startAppointmentPurchaseCallback) ?
                                                    <>
                                                        {
                                                            ga.services.some(s => s.fee > 0 && !s.isSubsidized) && !ga.purchases.some(o => o.referenceId === ga.id) ?
                                                                <>
                                                                    <MenuItem
                                                                        onClick={() => { startAppointmentPurchaseCallback(ga) }}
                                                                    >
                                                                        <span className='text-primary'>Create appointment invoice</span>
                                                                    </MenuItem>
                                                                    <MenuItem divider />
                                                                </> : null
                                                        }
                                                    </> : null
                                            }
                                            {
                                                startPurchaseCallback && fn.isFunction(startPurchaseCallback) ?
                                                    <MenuItem
                                                        onClick={() => { startPurchaseCallback(ga) }}
                                                    >
                                                        Start new invoice
                                                    </MenuItem> : null
                                            }
                                        </SubMenu>
                                    })
                                }
                            </SubMenu>
                        </> : null
                }
            </ContextMenu >
        }) : null
}

export const getAppointmentUri = (appointment) => {
    return appointment ?
        `${rts.Appointments.Home}/day/${moment(appointment.start).format('YYYY-MM-DD')}?id=${appointment.id}` : '';
}

export const checkConflicts = (data, checkUpcoming = false) => {
    return new Promise((resolve, reject) => {
        if (!data || !data.start) reject();

        const today = moment().startOf('day');
        const start = moment(data.start);
        const end = data.end ? moment(data.end) : start.clone().add((data.duration ? data.duration : 5), 'minutes');
        const requests = [
            api.BusinessDays.get(
                moment(data.start).format('YYYY-MM-DD')
            ),
            api.Schedules.get(
                moment(data.start).format('YYYY-MM-DD'),
                moment(data.start).format('YYYY-MM-DD'),
                data.userId
            ),
            api.Appointments.search({
                parameters: [
                    { field: 'Id', value: data.id, operator: '!=' },
                    { field: 'UserId', value: data.userId, },
                    { field: 'Start', value: end.clone().format('YYYY-MM-DDTHH:mm'), operator: '<' },
                    { field: 'End', value: start.clone().format('YYYY-MM-DDTHH:mm'), operator: '>' },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'Status', value: 'Booked', },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
        ];

        if (checkUpcoming) {
            requests.push(
                api.Appointments.search({
                    parameters: [
                        { field: 'Id', value: data.id, operator: '!=' },
                        { field: 'CustomerId', value: data.customerId, },
                        { field: 'RepeatId', value: null },
                        { field: 'DeactivatedDateUtc', value: null },
                        { field: 'Status', value: 'Booked', },
                    ],
                    includeTotalCount: false,
                    includeResult: true,
                }));
        }

        Promise.all(requests)
            .then(response => {
                const businessDay = response[0].data;
                const schedule = response[1].data && response[1].data.length > 0 ? response[1].data[0] : null;
                const hasBusinessDayConflict = businessDay ? businessDay.isClosed || start.isBefore(moment(`${moment(businessDay.date).format('YYYY-MM-DD')} ${businessDay.start}`, 'YYYY-MM-DD HH:mm')) || start.isSameOrAfter(moment(`${moment(businessDay.date).format('YYYY-MM-DD')} ${businessDay.end}`, 'YYYY-MM-DD HH:mm')) : true;
                const hasAppointmentConflict = response[2].data.total > 0;
                const hasUpcomingAppointmentConflict = !!checkUpcoming ? response[3].data && response[3].data.result.length > 0 && response[3].data.result.some(r => !moment(r.start).startOf('day').isSame(today) && r.services.some(s => s.fee > 0)) : false;
                const hasTimeOffConflict = schedule ? schedule.isTimeOff : false;
                const hasScheduleConflict = schedule ? !schedule.acceptBooking || start.isBefore(moment(schedule.start)) || start.isSameOrAfter(moment(schedule.end)) : true;
                const hasBreakConflict = schedule ? schedule.breaks && schedule.breaks.some(b => moment(b.start).isBefore(end) && moment(b.end).isAfter(start)) : false;

                if (hasUpcomingAppointmentConflict || hasAppointmentConflict || hasTimeOffConflict || hasScheduleConflict || (!hasScheduleConflict && hasBusinessDayConflict) || hasBreakConflict) {
                    resolve({
                        upcoming: hasUpcomingAppointmentConflict,
                        appointment: hasAppointmentConflict,
                        businessDay: hasBusinessDayConflict,
                        schedule: hasScheduleConflict,
                        break: hasBreakConflict,
                        timeOff: hasTimeOffConflict,
                    });
                } else {
                    resolve();
                }
            })
            .catch(error => {
                reject(error);
            })
    })
}

export const checkConflictsForGroup = (data, checkUpcoming = false) => {
    return new Promise((resolve, reject) => {
        if (!data || !data.start) reject();

        const start = moment(data.start);
        const end = data.end ? moment(data.end) : start.clone().add((data.duration ? data.duration : 5), 'minutes');
        const requests = [
            api.BusinessDays.get(
                moment(data.start).format('YYYY-MM-DD')
            ),
            api.Schedules.get(
                moment(data.start).format('YYYY-MM-DD'),
                moment(data.start).format('YYYY-MM-DD'),
                data.userId
            ),
            api.Appointments.search({
                parameters: [
                    { field: 'GroupId', value: data.groupId, operator: '!=' },
                    { field: 'UserId', value: data.userId, },
                    { field: 'Start', value: end.clone().format('YYYY-MM-DDTHH:mm'), operator: '<' },
                    { field: 'End', value: start.clone().format('YYYY-MM-DDTHH:mm'), operator: '>' },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'Status', value: 'Booked', },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
        ];

        if (checkUpcoming && data.customerIds && data.customerIds.length > 0) {
            for (let ci = 0; ci < data.customerIds.length; ci++) {
                if (data.customerIds[ci]) {
                    requests.push(
                        api.Appointments.search({
                            parameters: [
                                { field: 'GroupId', value: data.groupId, operator: '!=' },
                                { field: 'CustomerId', value: data.customerIds[ci], },
                                { field: 'Start', value: moment(), operator: '>=' },
                                { field: 'RepeatId', value: null },
                                { field: 'DeactivatedDateUtc', value: null },
                                { field: 'Status', value: 'Booked', },
                            ],
                            includeTotalCount: false,
                            includeResult: true,
                        }));
                }
            }
        }

        Promise.all(requests)
            .then(response => {
                const businessDay = response[0].data;
                const schedule = response[1].data && response[1].data.length > 0 ? response[1].data[0] : null;
                const hasBusinessDayConflict = businessDay ? businessDay.isClosed || start.isBefore(moment(`${moment(businessDay.date).format('YYYY-MM-DD')} ${businessDay.start}`, 'YYYY-MM-DD HH:mm')) || start.isSameOrAfter(moment(`${moment(businessDay.date).format('YYYY-MM-DD')} ${businessDay.end}`, 'YYYY-MM-DD HH:mm')) : true;
                const hasAppointmentConflict = response[2].data.total > 0;
                const upcomingAppointmentConflicts = [];

                if (!!checkUpcoming && response.length > 3) {
                    for (let ri = 3; ri < response.length; ri++) {
                        if (response[ri].data && response[ri].data.result.length > 0 && response[ri].data.result.some(r => r.services.some(s => s.fee > 0))) {
                            upcomingAppointmentConflicts.push(
                                response[ri].data.result[0].customer
                            );
                        }
                    }
                }

                const hasUpcomingAppointmentConflict = upcomingAppointmentConflicts && upcomingAppointmentConflicts.length > 0;
                const hasTimeOffConflict = schedule ? schedule.isTimeOff : false;
                const hasScheduleConflict = schedule ? !schedule.acceptBooking || start.isBefore(moment(schedule.start)) || start.isSameOrAfter(moment(schedule.end)) : true;
                const hasBreakConflict = schedule ? schedule.breaks && schedule.breaks.some(b => moment(b.start).isBefore(end) && moment(b.end).isAfter(start)) : false;

                if (hasUpcomingAppointmentConflict || hasAppointmentConflict || hasTimeOffConflict || hasScheduleConflict || (!hasScheduleConflict && hasBusinessDayConflict) || hasBreakConflict) {
                    resolve({
                        upcoming: upcomingAppointmentConflicts,
                        appointment: hasAppointmentConflict,
                        businessDay: hasBusinessDayConflict,
                        schedule: hasScheduleConflict,
                        break: hasBreakConflict,
                        timeOff: hasTimeOffConflict,
                    });
                } else {
                    resolve();
                }
            })
            .catch(error => {
                reject(error);
            })
    })
}

export const checkEligibility = (customer, data) => {
    return new Promise((resolve, reject) => {
        if (!customer || !data || !data.start || !data.services || data.services.length === 0) reject();

        api.ServiceEligibility.check({
            dateOfBirth: customer.dateOfBirth ? moment(customer.dateOfBirth) : null,
            healthCardNumber: customer.patientProfile ? customer.patientProfile.healthCardNumber : null,
            appointmentDate: moment(data.start).startOf('day'),
            serviceIds: data.services.map(s => { return s.id })
        })
            .then(({ data }) => {
                const filtered = data && data.some(d => d.service.isSubsidized) ?
                    data.filter(d => d.service.isSubsidized)
                        .map(d => {
                            d.customer = customer;
                            return d
                        }) : null;

                resolve(filtered);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export const checkEligibilityForGroup = (batch) => {
    return new Promise((resolve, reject) => {
        if (!batch || batch.length === 0) reject();

        const requests = batch.map(b => {
            return checkEligibility(b.customer, b.data)
        })

        Promise.all(requests)
            .then(data => {
                const results = [].concat(...data).filter(d => !!d);
                resolve(results);
            })
            .catch(error => {
                reject(error);
            })
    });
}

const renderIndividualAppointmentTooltipContent = (event, age, gender, extendedProps) => {
    return <div className='text-left'>
        <p className='fs-sm mb-0'>
            {
                extendedProps.appointment.notes && extendedProps.appointment.notes.some(n => n.referenceId === extendedProps.appointment.id) ?
                    <i className='fal fa-comment-alt-lines text-info-100 mr-1'></i> : null
            }
            {
                extendedProps.appointment.notes && extendedProps.appointment.notes.some(n => n.isPinned) ?
                    <i className='fas fa-thumbtack text-info-300 rotate-45 flashing mr-1'></i> : null
            }
            {
                extendedProps.appointment.customer.hasTodayBalance && extendedProps.appointment.customer.hasOutstandingBalance ?
                    <i className='fas fa-dollar-sign text-danger flashing mr-1'></i> : null
            }
            {
                extendedProps.appointment.status === 'Completed' ?
                    <i className='fas fa-check text-success-600 mr-1'></i> : null
            }
            {
                extendedProps.appointment.status === 'NoShow' ?
                    <i className='fas fa-exclamation text-danger mr-1'></i> : null
            }
            <strong>{event.title}</strong> {(age || gender) ? <small>({(`${age} ${gender}`).trim()})</small> : null}
            <span className='ml-2'>
                {bh.renderAppointmentNew(extendedProps.appointment, 'fs-sm mr-1')}
                {bh.renderAppointmentFirstExam(extendedProps.appointment, 'fs-sm mr-1')}
                {bh.renderAppointmentPrebook(extendedProps.appointment, 'fs-sm mr-1')}
                {
                    extendedProps.appointment.services.map((s, si) => {
                        return <Fragment
                            key={`service-${si}`}
                        >
                            {bh.renderServiceCode(s, 'fs-sm mr-1', (!s.isEligible || !isEndState(extendedProps.appointment)))}
                        </Fragment>
                    })
                }
            </span>
        </p>
        <p className='fs-sm mb-0'>
            <small>
                {fn.formatTimeFromToHtml(moment(extendedProps.appointment.start), moment(extendedProps.appointment.end))}
                {
                    extendedProps.appointment.isRepeating ?
                        <span className='ml-1 text-gray-500'>(# {extendedProps.appointment.repeatIndex} of {extendedProps.appointment.repeatTotal})</span> : null
                }
            </small>
        </p>
        {
            extendedProps.appointment.notes && extendedProps.appointment.notes.length > 0 ?
                // <span className='fs-sm html text-gray-500' dangerouslySetInnerHTML={{ __html: extendedProps.appointment.notes[0].bodyHtml }}></span> : null
                <span className='fs-sm html text-gray-500' dangerouslySetInnerHTML={{ __html: extendedProps.appointment.notes.slice(-3).map(n => { return `<div>${n.bodyHtml}</div>` }).reduce((a, b) => `${a}${b}`) }}></span> : null
        }
    </div>
}

const renderGroupAppointmentTooltipContent = (event, age, gender, extendedProps) => {
    const notes = groupAppointmentNotes(extendedProps.appointments);

    return <div className='text-left'>
        <p className='fs-sm mb-0'>
            {
                notes && notes.some(n => extendedProps.appointments.some(a => a.id === n.referenceId)) ?
                    <i className='fal fa-comment-alt-lines text-info-100 mr-1'></i> : null
            }
            {
                extendedProps.appointment.notes && extendedProps.appointment.notes.some(n => n.isPinned) ?
                    <i className='fas fa-thumbtack text-info-300 rotate-45 flashing mr-1'></i> : null
            }
            {
                extendedProps.appointment.customer.hasTodayBalance && extendedProps.appointment.customer.hasOutstandingBalance ?
                    <i className='fas fa-dollar-sign text-danger flashing mr-1'></i> : null
            }
            {
                extendedProps.appointment.status === 'Completed' ?
                    <i className='fas fa-check text-success-600 mr-1'></i> : null
            }
            {
                extendedProps.appointment.status === 'NoShow' ?
                    <i className='fas fa-exclamation text-danger mr-1'></i> : null
            }
            <strong>{event.title}</strong> {(age || gender) ? <small>({(`${age} ${gender}`).trim()})</small> : null}
            <span className='ml-2'>
                {bh.renderAppointmentNew(extendedProps.appointment, 'fs-sm mr-1')}
                {bh.renderAppointmentFirstExam(extendedProps.appointment, 'fs-sm mr-1')}
                {bh.renderAppointmentPrebook(extendedProps.appointment, 'fs-sm mr-1')}
                {
                    extendedProps.appointment.services.map((s, si) => {
                        return <Fragment
                            key={`service-${si}`}
                        >
                            {bh.renderServiceCode(s, 'fs-sm mr-1', (!s.isEligible || !isEndState(extendedProps.appointment)))}
                        </Fragment>
                    })
                }
            </span>
        </p>
        <p className='fs-sm mb-0'>
            <small>
                {fn.formatTimeFromToHtml(moment(extendedProps.appointment.start), moment(extendedProps.appointment.end))}
                {
                    extendedProps.appointment.isRepeating ?
                        <span className='ml-1 text-gray-500'>(# {extendedProps.appointment.repeatIndex} of {extendedProps.appointment.repeatTotal})</span> : null
                }
            </small>
        </p>
        {
            notes && notes.length > 0 ?
                // <span className='fs-sm html text-gray-500' dangerouslySetInnerHTML={{ __html: extendedProps.appointment.notes[0].bodyHtml }}></span> : null
                <span className='fs-sm html text-gray-500' dangerouslySetInnerHTML={{ __html: notes.slice(-3).map(n => { return `<div>${n.bodyHtml}</div>` }).reduce((a, b) => `${a}${b}`) }}></span> : null
        }
    </div>
}