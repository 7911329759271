import React, { useContext, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from '../utilities/appInsights';
import { usePageVisibility } from 'react-page-visibility';
import { observer, Observer } from 'mobx-react-lite';
import { useAuth0 } from "@auth0/auth0-react";
import loadable from '@loadable/component';
import moment from 'moment';

import LoadingOverlay from './views/_shared/LoadingOverlay';
import ErrorBoundary from './views/_shared/ErrorBoundary';
import ErrorBoundaryFatal from './views/_shared/ErrorBoundaryFatal';
import QuickDrawer from './views/_shared/QuickDrawer';
import ConfirmModal from './views/_shared/ConfirmModal';
import RefreshModal from './views/_shared/RefreshModal';
import MaintenanceModal from './views/_shared/MaintenanceModal';
import PublicInsuranceVerificationModal from './views/public-insurances/PublicInsuranceVerificationModal/_index';
import FileViewer from './views/_shared/FileViewer';
import HotKeys from './views/_shared/HotKeys';
import LockScreen from './views/_shared/LockScreen';
import SwitchPinScreen from './views/_shared/SwitchPinScreen';
import Sidebar from './views/_shared/Sidebar';
import Navbar from './views/_shared/Navbar';
import ToastNotification from './views/_shared/ToastNotification';
import BodyEnd from './views/_shared/BodyEnd';
import Logout from './views/logout/_index';

import AuthStore from '../stores/AuthStore';
import SearchBarStore from '../stores/SearchBarStore';
import SettingStore from '../stores/SettingStore';

import * as AccessType from '../constants/accessTypes';
import * as StorageKey from '../constants/storageKeys';
import * as SystemSettings from '../constants/systemSettingKeys';
import * as rts from '../constants/routes';
import * as ah from '../utilities/accessHelper';
import * as rh from './views/reports/_common/ReportHelper'

import api from '../api';

import 'devextreme/dist/css/dx.light.compact.css';

// Change this to force a new js hash for deployment. 
const version = '91f795c1-fc87-4a9f-b3eb-36856a282cdd'; // eslint-disable-line

const Home = loadable(() => routeLoader(() => import('./views/_index')));
const Login = loadable(() => routeLoader(() => import('./views/login/_index')));
const Authenticate = loadable(() => routeLoader(() => import('./views/authenticate/_index')));
const Redirect = loadable(() => routeLoader(() => import('./views/redirect/_index')));
const Download = loadable(() => routeLoader(() => import('./views/download/_index')));
const Form = loadable(() => routeLoader(() => import('./views/forms/_index')));
const AppointmentConfirmError = loadable(() => routeLoader(() => import('./views/appointments/ConfirmError')));
const AppointmentConfirming = loadable(() => routeLoader(() => import('./views/appointments/Confirming')));
const AppointmentConfirmed = loadable(() => routeLoader(() => import('./views/appointments/Confirmed')));
const ReviewRequest = loadable(() => routeLoader(() => import('./views/reviews/Request')));
const ReviewFeedback = loadable(() => routeLoader(() => import('./views/reviews/Feedback')));
const PageNotFound = loadable(() => routeLoader(() => import('./views/PageNotFound')));

const Appointments = loadable(() => routeLoader(() => import('./views/appointments/_index')));
const CustomerList = loadable(() => routeLoader(() => import('./views/customers/List')));
const CustomerProfile = loadable(() => routeLoader(() => import('./views/customers/Profile')));
const Purchases = loadable(() => routeLoader(() => import('./views/purchases/_index')));
const UserManagement = loadable(() => routeLoader(() => import('./views/admin/organization/user-management/_index')));
const TenantManagement = loadable(() => routeLoader(() => import('./views/admin/organization/tenants/_index')));
const OrganizationManagement = loadable(() => routeLoader(() => import('./views/admin/organization/organizations/_index')));
const SwitchTenant = loadable(() => routeLoader(() => import('./views/admin/switch-tenant/_index')));

const ExamList = loadable(() => routeLoader(() => import('./views/exams/List')));
const ExamView = loadable(() => routeLoader(() => import('./views/exams/View')));
const Quotes = loadable(() => routeLoader(() => import('./views/quotes/_index')));
const OrderList = loadable(() => routeLoader(() => import('./views/work-orders/List')));
const OrderView = loadable(() => routeLoader(() => import('./views/work-orders/View')));
const Services = loadable(() => routeLoader(() => import('./views/services/_index')));
const Products = loadable(() => routeLoader(() => import('./views/products/_index')));
const Schedules = loadable(() => routeLoader(() => import('./views/schedules/_index')));
const Referrals = loadable(() => routeLoader(() => import('./views/referrals/_index')));
const Reports = loadable(() => routeLoader(() => import('./views/reports/_index')));
const SearchView = loadable(() => routeLoader(() => import('./views/search/_index')));

const UserProfile = loadable(() => routeLoader(() => import('./views/admin/organization/user-management/users/Profile')));

const ExamTemplateList = loadable(() => routeLoader(() => import('./views/settings/exam/templates/List')));
const ExamTemplateTemplate = loadable(() => routeLoader(() => import('./views/settings/exam/templates/Template')));
const ExamTemplateInput = loadable(() => routeLoader(() => import('./views/settings/exam/inputs/_index')));
const ExamDataset = loadable(() => routeLoader(() => import('./views/settings/exam/datasets/_index')));
const WorkOrderTemplateList = loadable(() => routeLoader(() => import('./views/settings/work-order/templates/List')));
const WorkOrderTemplateView = loadable(() => routeLoader(() => import('./views/settings/work-order/templates/Template')));
const WorkOrderTemplateInput = loadable(() => routeLoader(() => import('./views/settings/work-order/inputs/_index')));
const PrescriptionPrintTemplateList = loadable(() => routeLoader(() => import('./views/settings/prescription-print/templates/List')));
const PrescriptionPrintTemplateView = loadable(() => routeLoader(() => import('./views/settings/prescription-print/templates/Template')));
const PrescriptionTemplateList = loadable(() => routeLoader(() => import('./views/settings/prescription/templates/List')));
const PrescriptionTemplateView = loadable(() => routeLoader(() => import('./views/settings/prescription/templates/Template')));
const PrescriptionTemplateInput = loadable(() => routeLoader(() => import('./views/settings/prescription/inputs/_index')));
const PretestLogicalDeviceList = loadable(() => routeLoader(() => import('./views/settings/pretest/logicalDevices/List')));
const PretestDeviceSyncDownloadList = loadable(() => routeLoader(() => import('./views/settings/pretest/deviceSync/List')));
const PretestTemplateList = loadable(() => routeLoader(() => import('./views/settings/pretest/templates/List')));
const PretestTemplateView = loadable(() => routeLoader(() => import('./views/settings/pretest/templates/Template')));
const PretestTemplateInput = loadable(() => routeLoader(() => import('./views/settings/pretest/inputs/_index')));
const FaxCoverPageTemplate = loadable(() => routeLoader(() => import('./views/settings/fax-cover-page/templates/_index')));
const InvoiceTemplate = loadable(() => routeLoader(() => import('./views/settings/invoice/templates/_index')));
const PaymentReceiptTemplate = loadable(() => routeLoader(() => import('./views/settings/payment-receipt/templates/_index')));
const FormTemplateList = loadable(() => routeLoader(() => import('./views/settings/form/templates/List')));
const FormTemplateView = loadable(() => routeLoader(() => import('./views/settings/form/templates/Template')));
const InternalFormTemplateList = loadable(() => routeLoader(() => import('./views/settings/internal-form/templates/List')));
const InternalFormTemplateView = loadable(() => routeLoader(() => import('./views/settings/internal-form/templates/Template')));
const ReferralTemplate = loadable(() => routeLoader(() => import('./views/settings/referral/templates/_index')));
const ReferralTenantExamDatasetGroup = loadable(() => routeLoader(() => import('./views/settings/referral/datasetGroups/_index')));
const MedicalReportTemplate = loadable(() => routeLoader(() => import('./views/settings/medical-report/templates/_index')));
const MedicalReportTenantExamDatasetGroup = loadable(() => routeLoader(() => import('./views/settings/medical-report/datasetGroups/_index')));
const CommunicationTemplateList = loadable(() => routeLoader(() => import('./views/settings/communication/templates/List')));
const CommunicationTemplateView = loadable(() => routeLoader(() => import('./views/settings/communication/templates/Template')));
const UserAccount = loadable(() => routeLoader(() => import('./views/settings/user/_index')));

const PublicInsuranceBillingReport = loadable(() => routeLoader(() => import('./views/public-insurances/_index')));
const TodoList = loadable(() => routeLoader(() => import('./views/todo/_index')));
// const NotificationList = loadable(() => routeLoader(() => import('./views/notifications/_index')));
const CommunicationList = loadable(() => routeLoader(() => import('./views/communications/_index')));

const SubscriptionPlanList = loadable(() => routeLoader(() => import('./views/subscription/_index')));
const CheckoutStatus = loadable(() => routeLoader(() => import('./views/subscription/CheckoutStatus')));

const Test = loadable(() => { return import('./views/_test/_index') });

const configuration = {
    lockscreen: `${process.env.REACT_APP_CDN_URL}/lockscreen/${Math.ceil(Math.random() * 80)}.jpg`,
}

const routeLoader = (factory, maxRetry = 3) => {
    return new Promise((resolve, reject) => {
        factory()
            .then(resolve)
            .catch((error) => {
                if (maxRetry === 0) {
                    reject(error);
                    window.location.reload();
                    return;
                }

                routeLoader(factory, --maxRetry).then(resolve, reject);
            })
    })
}

const App = observer(({ isFailover }) => {
    let { isLoading, isAuthenticated } = useAuth0();
    const auth = useContext(AuthStore);

    if (isFailover) {
        isLoading = false;
        // In the case of failover, you won't even see this component unless you're already logged in, hence isAuthenticated can be set to true
        isAuthenticated = true;
    }

    if (isLoading) {
        return <LoadingOverlay isLoading={true}></LoadingOverlay>
    }
    const isAppInsightEnabled = !!process.env.REACT_APP_APPINSIGHTS_KEY;

    const getBrowserRouter = () => {
        // console.log('isAuthenticated', isAuthenticated);
        // console.log('auth.isSessionActive', auth.isSessionActive);
        // console.log('auth.isSessionValid', auth.isSessionValid);

        return <BrowserRouter>
            <ErrorBoundaryFatal>
                <Routes>
                    <Route path='/' element={
                        isAuthenticated && auth.isSessionActive
                            ? <Navigate replace to={rts.Appointments.Home} />
                            : (!isAuthenticated && !auth.isSessionActive && auth.isSessionValid  // Added "isSessionValid" - session could've expired while the browser is closed.  In that case, we still need to force a logout because the login state at Auth0 could still be authenticated.
                                ? <Home /> : <Navigate replace to={rts.Logout} />)  // Force a logout if either isAuthenticated or isSessionActive is true.
                    }></Route>
                    <Route path={rts.Logout} element={<Logout isFailover={isFailover} />} />
                    <Route path={rts.Authenticate} element={<Authenticate />} />
                    <Route path={rts.Redirect} element={<Redirect />} />
                    <Route path={`${rts.Download}/:key`} element={<Download />} />
                    {
                        !isFailover ?
                            <Route path={rts.Login} element={
                                !isAuthenticated && !auth.isSessionActive && auth.isSessionValid
                                    ? <Login /> // Login component is only used in the Auth0 scenario
                                    : <Logout />
                            } />
                            : null
                    }
                    <Route path={`${rts.Forms}/:id`} element={<Form />} />
                    <Route path={`${rts.AppointmentConfirmError}`} element={<AppointmentConfirmError />} />
                    <Route path={`${rts.AppointmentConfirming}`} element={<AppointmentConfirming />} />
                    <Route path={`${rts.AppointmentConfirmed}`} element={<AppointmentConfirmed />} />
                    <Route path={`${rts.ReviewRequest}`} element={<ReviewRequest />} />
                    <Route path={`${rts.ReviewFeedback}`} element={<ReviewFeedback />} />

                    <Route path={`${rts.Appointments.Home}`} element={<PrivateRoute access={AccessType.VIEW_APPOINTMENT} component={Appointments}></PrivateRoute>} />
                    <Route path={`${rts.Appointments.Home}/:mode/:start`} element={<PrivateRoute access={AccessType.VIEW_APPOINTMENT} component={Appointments}></PrivateRoute>} />
                    <Route path={`${rts.Customers.Home}`} element={<PrivateRoute access={AccessType.VIEW_CUSTOMER} component={CustomerList}></PrivateRoute>} />
                    <Route path={`${rts.Customers.Home}/:id`} element={<PrivateRoute access={AccessType.VIEW_CUSTOMER} component={CustomerProfile}></PrivateRoute>} />
                    <Route path={`${rts.Purchases.Home}/:mode`} element={<PrivateRoute access={AccessType.VIEW_PURCHASE} component={Purchases}></PrivateRoute>} />
                    <Route path={`${rts.Exams.List}`} element={<PrivateRoute access={AccessType.VIEW_EXAM} component={ExamList}></PrivateRoute>} />
                    <Route path={`${rts.Exams.List}/:mode/:start`} element={<PrivateRoute access={AccessType.VIEW_EXAM} component={ExamList}></PrivateRoute>} />
                    <Route path={`${rts.Exams.View}/:appointmentId`} element={<PrivateRoute access={AccessType.VIEW_EXAM} component={ExamView}></PrivateRoute>} />
                    <Route path={`${rts.Services.Home}`} element={<PrivateRoute access={AccessType.VIEW_SERVICE} component={Services}></PrivateRoute>} />
                    <Route path={`${rts.Products.Home}`} element={<PrivateRoute access={AccessType.VIEW_PRODUCT} component={Products}></PrivateRoute>} />
                    <Route path={`${rts.Quotes.Home}`} element={<PrivateRoute access={AccessType.VIEW_PURCHASE} component={Quotes}></PrivateRoute>} />
                    <Route path={`${rts.WorkOrders.List}`} element={<PrivateRoute access={AccessType.VIEW_WORK_ORDER} component={OrderList}></PrivateRoute>} />
                    <Route path={`${rts.WorkOrders.View}/:id`} element={<PrivateRoute access={AccessType.VIEW_WORK_ORDER} component={OrderView}></PrivateRoute>} />
                    <Route path={`${rts.Schedules.Home}`} element={<PrivateRoute access={AccessType.VIEW_SCHEDULE} component={Schedules}></PrivateRoute>} />
                    <Route path={`${rts.Schedules.Home}/:mode/:start`} element={<PrivateRoute access={AccessType.VIEW_SCHEDULE} component={Schedules}></PrivateRoute>} />
                    <Route path={`${rts.Tasks.Home}`} element={<PrivateRoute component={TodoList}></PrivateRoute>} />
                    <Route path={`${rts.Communications.Home}/:mode`} element={<PrivateRoute access={AccessType.VIEW_COMMUNICATION_SUMMARY} component={CommunicationList}></PrivateRoute>} />
                    <Route path={`${rts.PublicInsuranceBillingReport.Home}`} element={<PrivateRoute access={AccessType.VIEW_PUBLIC_INSURANCE_BILLING_REPORT} component={PublicInsuranceBillingReport}></PrivateRoute>} />
                    <Route path={`${rts.Referrals.Home}`} element={<PrivateRoute access={AccessType.VIEW_REFERRAL_APPOINTMENT} component={Referrals}></PrivateRoute>} />
                    <Route path={`${rh.ReportDefinition.Home.path}`} element={<PrivateRoute access={AccessType.VIEW_REPORT} component={Reports}></PrivateRoute>} />
                    <Route path={`${rh.ReportDefinition.Home.path}/:name`} element={<PrivateRoute access={AccessType.VIEW_REPORT} component={Reports}></PrivateRoute>} />
                    <Route path={`${rts.SearchResults.Home}`} element={<PrivateRoute access={AccessType.VIEW_CUSTOMER} component={SearchView}></PrivateRoute>} />
                    <Route path={`${rts.UserManagement.Home}`} element={<PrivateRoute access={AccessType.UPDATE_USER_GROUP} component={UserManagement}></PrivateRoute>} />
                    <Route path={`${rts.UserManagement.Home}/:mode`} element={<PrivateRoute access={AccessType.UPDATE_USER_GROUP} component={UserManagement}></PrivateRoute>} />
                    <Route path={`${rts.UserManagement.Users}/:id`} element={<PrivateRoute access={AccessType.UPDATE_USER_GROUP} component={UserProfile}></PrivateRoute>} />
                    <Route path={`${rts.TenantManagement.Home}`} element={<PrivateRoute access={AccessType.UPDATE_ORGANIZATION} component={TenantManagement}></PrivateRoute>} />
                    <Route path={`${rts.SwitchTenant.Home}`} element={<PrivateRoute access={AccessType.VIEW_APPOINTMENT} component={SwitchTenant}></PrivateRoute>} />
                    <Route path={`${rts.OrganizationManagement.Home}`} element={<PrivateRoute access={AccessType.UPDATE_ORGANIZATION} component={OrganizationManagement}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ExamTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ExamTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ExamTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ExamTemplateTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ExamInput}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ExamTemplateInput}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ExamDataset}/:examPublishedTemplateId`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ExamDataset}></PrivateRoute>} />
                    <Route path={`${rts.Settings.WorkOrderTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={WorkOrderTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.WorkOrderTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={WorkOrderTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.WorkOrderInput}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={WorkOrderTemplateInput}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PrescriptionPrintTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PrescriptionPrintTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PrescriptionPrintTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PrescriptionPrintTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PrescriptionTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PrescriptionTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PrescriptionTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PrescriptionTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PrescriptionInput}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PrescriptionTemplateInput}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PretestLogicalDevices}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PretestLogicalDeviceList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PretestDeviceSyncDownload}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PretestDeviceSyncDownloadList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PretestTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PretestTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PretestTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PretestTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PretestInput}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PretestTemplateInput}></PrivateRoute>} />
                    <Route path={`${rts.Settings.FaxCoverPageTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={FaxCoverPageTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.InvoiceTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={InvoiceTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.PaymentReceiptTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={PaymentReceiptTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.FormTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={FormTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.FormTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={FormTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.InternalFormTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={InternalFormTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.InternalFormTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={InternalFormTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ReferralTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ReferralTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.ReferralTenantExamDatasetGroup}/:examPublishedTemplateId`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={ReferralTenantExamDatasetGroup}></PrivateRoute>} />
                    <Route path={`${rts.Settings.MedicalReportTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={MedicalReportTemplate}></PrivateRoute>} />
                    <Route path={`${rts.Settings.MedicalReportTenantExamDatasetGroup}/:examPublishedTemplateId`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={MedicalReportTenantExamDatasetGroup}></PrivateRoute>} />
                    <Route path={`${rts.Settings.CommunicationTemplate}`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={CommunicationTemplateList}></PrivateRoute>} />
                    <Route path={`${rts.Settings.CommunicationTemplate}/:id`} element={<PrivateRoute access={AccessType.UPDATE_SYSTEM_SETTING} component={CommunicationTemplateView}></PrivateRoute>} />
                    <Route path={`${rts.Settings.UserAccount}`} element={<PrivateRoute component={UserAccount}></PrivateRoute>} />
                    <Route path={`${rts.Subscription.Plans}`} element={<PrivateRoute access={AccessType.UPDATE_SUBSCRIPTION} component={SubscriptionPlanList}></PrivateRoute>} />
                    <Route path={`${rts.Subscription.CheckoutStatus}`} element={<PrivateRoute access={AccessType.UPDATE_SUBSCRIPTION} component={CheckoutStatus}></PrivateRoute>} />
                    <Route path={'/test'} element={<Test />} />

                    <Route path={rts.PageNotFound} element={<PageNotFound />} />
                    <Route path='*' element={<PageNotFound />} />
                </Routes>
            </ErrorBoundaryFatal>
        </BrowserRouter>
    }

    const PrivateRoute = ({ access: Access, component: Component, hasNavigation }) => {
        return (isAuthenticated && auth.isSessionActive) ?
            <>
                {
                    !Access || ah.check(Access)
                        ? hasNavigation === false ? Component : <ComponentWrapper component={Component} />
                        : <Navigate replace to={'/'} />
                }
            </> : <Navigate replace to={rts.Logout} />;
    }

    const ComponentWrapper = ({ component: Component, showFooter: ShowFooter, ...props }) => {
        const setting = useContext(SettingStore);
        const searchbar = useContext(SearchBarStore);
        const isCheckingSettings = useRef();
        const refreshModalRef = useRef();
        const maintenanceModalRef = useRef();
        const pageVisibility = usePageVisibility();

        useEffect(() => {
            window.addEventListener('focus', handleWhenFocused, false);
            window.addEventListener('blur', handleWhenFocused, false);

            return () => {
                window.removeEventListener('focus', handleWhenFocused);
                window.removeEventListener('blur', handleWhenFocused);
            }
        }, []); // eslint-disable-line

        useEffect(() => {
            handleWhenFocused();
        }, [pageVisibility]); // eslint-disable-line

        if (isAuthenticated && auth.isSessionActive && auth.currentUser && auth.currentTenant) {
            moment().tz(auth.currentTenant.timeZoneId);
        }

        const handleWhenFocused = () => {
            if (!isAuthenticated || !auth.isSessionActive) {
                // In the case of Failover, this doesn't really redirect to Logout component, because the toplevel Index.js will display the Login page if session is not active
                window.location.href = rts.Logout;
            }

            if (auth && auth.currentUser && auth && auth.currentUser.id) {
                const sessionField = document.querySelector('#_si');
                const browserSessionId = sessionField ? sessionField.value : null;
                let storageSessionId = window.localStorage.getItem(StorageKey.SESSION_ID);

                if (!browserSessionId && !!storageSessionId && sessionField) {
                    sessionField.value = storageSessionId;
                }
                else if (!!browserSessionId && !!storageSessionId && browserSessionId !== storageSessionId) {
                    storageSessionId = null;
                    window.location.reload();
                    return;
                }

                if (process.env.NODE_ENV !== 'development') {
                    if (!!storageSessionId) {
                        if (!isCheckingSettings.current) {
                            isCheckingSettings.current = true;

                            api.SystemSettings.all()
                                .then(({ data }) => {
                                    const plannedMaintenanceWindow = data && data.some(d => d.key === SystemSettings.PLANNED_MAINTENANCE_WINDOW) ? data.filter(d => d.key === SystemSettings.PLANNED_MAINTENANCE_WINDOW)[0] : null;
                                    const deployedJs = data && data.some(d => d.key === SystemSettings.DEPLOYED_JAVASCRIPT) ? data.filter(d => d.key === SystemSettings.DEPLOYED_JAVASCRIPT)[0] : null;
                                    const deployedJsBreaking = data && data.some(d => d.key === SystemSettings.DEPLOYED_JAVASCRIPT_BREAKING) ? data.filter(d => d.key === SystemSettings.DEPLOYED_JAVASCRIPT_BREAKING)[0] : null;
                                    let isLatestJs = !deployedJs;

                                    if (deployedJs) {
                                        if (!Array.from(document.querySelectorAll(`script[src="${deployedJs.value}"]`))[0]) {
                                            const isBreakingUpdate = deployedJsBreaking && deployedJsBreaking.value === deployedJs.value;
                                            const refreshLaterExpiresAt = JSON.parse(window.localStorage.getItem(StorageKey.REFRESH_LATER_EXPIRES_AT));
                                            const showRefreshModal = !refreshLaterExpiresAt || (new Date().getTime() > refreshLaterExpiresAt);

                                            if (isBreakingUpdate || showRefreshModal) {
                                                refreshModalRef.current.show(isBreakingUpdate);
                                            }
                                        }
                                        else {
                                            isLatestJs = true;
                                        }
                                    }

                                    if (isLatestJs && plannedMaintenanceWindow && plannedMaintenanceWindow.value) {
                                        const timeParts = plannedMaintenanceWindow.value.split('|');
                                        const plannedMaintenanceLaterExpiresAt = JSON.parse(window.localStorage.getItem(StorageKey.PLANNED_MAINTENANCE_LATER_EXPIRES_AT));
                                        const showMaintenanceModal = !plannedMaintenanceLaterExpiresAt || (new Date().getTime() > plannedMaintenanceLaterExpiresAt);
                                        let from, to;
                                        let isValid = false;

                                        if (timeParts) {
                                            if (timeParts.length === 1) {
                                                from = moment(timeParts[0]);
                                                isValid = from.isValid() && from.isAfter(moment());
                                            }
                                            else if (timeParts.length === 2) {
                                                from = moment(timeParts[0]);
                                                to = moment(timeParts[1]);
                                                isValid = from.isValid() && to.isValid() && from.isBefore(to) && from.isAfter(moment());
                                            }
                                        }

                                        if (isValid && showMaintenanceModal) {
                                            maintenanceModalRef.current.show(from, to);
                                        }
                                    }
                                })
                                .finally(() => {
                                    setTimeout(() => {
                                        isCheckingSettings.current = false;
                                    }, 1000);
                                })
                        }
                    }
                }
            }
        }

        return (<>
            <HotKeys />
            <SwitchPinScreen />
            <LockScreen imageUrl={configuration.lockscreen}>
                <Observer>{() =>
                    <div className={'mod-bg-1 mod-nav-link header-function-fixed nav-function-fixed modal-open blur' + (setting.isSidebarOpened ? '' : ' nav-function-minify')}>
                        <div className='page-wrapper'>
                            <div className='page-inner'>
                                <Sidebar />
                                <div className='page-content-wrapper'>
                                    <Navbar />
                                    <main role='main' className='page-content'>
                                        <ErrorBoundary>
                                            <div className={'searchbar-overlay' + (searchbar.isActivated ? ' active' : '')} onClick={() => { searchbar.isActivated = false; }} />
                                            <QuickDrawer />
                                            <Component {...props} />
                                        </ErrorBoundary>
                                    </main>
                                </div>
                            </div>
                        </div>
                        <BodyEnd>
                            <>
                                <ConfirmModal />
                                <FileViewer />
                                <RefreshModal ref={refreshModalRef} />
                                <MaintenanceModal ref={maintenanceModalRef} />
                                <PublicInsuranceVerificationModal />
                            </>
                        </BodyEnd>
                    </div>
                }
                </Observer>
            </LockScreen>
            <ToastNotification />
        </>)
    }

    return (<>
        {
            isAppInsightEnabled ?
                <AppInsightsContext.Provider value={reactPlugin}>
                    {
                        getBrowserRouter()
                    }
                </AppInsightsContext.Provider> :
                <>
                    {
                        getBrowserRouter()
                    }
                </>
        }
    </>)
})

export default App;